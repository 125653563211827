import {
  getReq,
  postReq,
  putReq,
  getAllHotelsParams,
  hotelDetailsByIdParams,
  hotelDetailsByIdModel,
  getMyBookingsParams,
  hotelRoomRatesParams,
  bookingDetailsByIdModel,
} from 'services';

const baseUrl = `${apiUrls.voyageApiUrl}/api/Hotels`;

export const getAllHotels = (options) => getReq(`${baseUrl}/all?${getAllHotelsParams(options)}`);

export const getHotelById = async (hotelId, options) => {
  const [res, err] = await getReq(`${baseUrl}/${hotelId}?${hotelDetailsByIdParams(options)}`);
  return [hotelDetailsByIdModel(res), err];
};

export const getHotelRoomRates = async (hotelId, options) =>
  getReq(`${baseUrl}/${hotelId}/RoomRates?${hotelRoomRatesParams(options)}`);

export const createHotelBookings = (bookingData) => postReq(`${baseUrl}/Bookings`, bookingData);

export const cancelBookingHotelByIds = (cancelBody) => putReq(`${baseUrl}/Bookings/Cancel`, cancelBody);

export const getMyBookings = (options) => getReq(`${baseUrl}/Bookings/My?${getMyBookingsParams(options)}`);

export const getBookingById = async (bookingId) => {
  const [res, err] = await getReq(`${baseUrl}/v2/Bookings/${bookingId}`);
  return [bookingDetailsByIdModel(res), err];
};
