import { theme } from 'styles';

export const cardContainer = (hasDiscount) => ({
  padding: 16,

  '.hotel-image': {
    borderRadius: 10,
    width: 300,
    height: 216,

    '@media (max-width: 576px)': {
      width: '100%',
    },
  },

  '.hotel-info': {
    backgroundColor: theme.gray100,
    borderRadius: '10px 0px 0px 10px',
    padding: 16,

    '.info': {
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,

      '.entertainment-badge': {
        borderRadius: 25,
        margin: '0 8px 8px 0',

        '.badge-container': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
      },

      '.location': {
        color: theme.gray300,
        marginBottom: 8,
        fontSize: 14,
      },

      '.amenities': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.successDark,

        '.amenities-info': {
          marginRight: 4,
        },
      },
    },

    '@media (max-width: 767px)': {
      flexDirection: 'column',
    },

    '@media (max-width: 576px)': {
      borderRadius: '10px 10px 0px 0px',
    },
  },

  '.price-info': {
    backgroundColor: theme.gray50,
    borderRadius: '0px 10px 10px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: 16,

    '.column': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'center',
      height: '100%',
      width: '100%',

      '@media (max-width: 576px)': {
        alignItems: 'flex-start',
      },
    },

    '& > *': {
      marginBottom: 8,
    },

    '.price': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',

      '.original-price': {
        color: theme.gray300,
        marginRight: 8,
        textDecoration: 'line-through',
      },

      '.discounted-price': {
        color: hasDiscount ? theme.successDark : theme.gray800,
        fontSize: 14,
      },
    },

    '.for-night': {
      color: theme.gray200,
    },

    '@media (max-width: 576px)': {
      borderRadius: '0px 0px 10px 10px',
    },
  },
});
