import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isArray, isEmpty, isFunction, isNil } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Button, Icon, useUrlParams, Dropdown, Row, Col } from 'components';
import CounterInput from '../../../shared/CounterInput';
import { childAgeOptions } from './config';
import { visitorsContainer, visitors } from './styles';

const VisitorsInput = (props) => {
  const { values: externalValues, className, tooltipClassName, onChange } = props;
  const [openVisitors, setOpenVisitors] = useState(false);
  const [values, setValues] = useState(externalValues ?? {});
  const [showError, setShowError] = useState(false);
  const { queryParams } = useUrlParams();
  const { getText } = useTranslations();
  const adultsRef = useRef();
  const childsRef = useRef();
  const roomsRef = useRef();

  const disableButton =
    (values?.children > 0 && isEmpty(values?.childrenAges)) ||
    values?.childrenAges?.length !== values?.children ||
    values?.rooms === 0 ||
    values?.adults === 0 ||
    showError;

  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      childrenAges: isArray(prev?.childrenAges)
        ? prev?.childrenAges?.slice(0, values?.children)
        : prev.childrenAges
        ? [prev.childrenAges]
        : [],
    }));
  }, [values?.children]);

  const hideContainer = (event = {}) => {
    event.preventDefault();

    const { currentTarget, relatedTarget } = event;
    event.stopPropagation();

    if (currentTarget.contains(relatedTarget) || relatedTarget) return;
    setOpenVisitors(false);
    setValues(externalValues ?? []);
  };

  const handleApplyChanges = (event = {}) => {
    event.stopPropagation();
    setOpenVisitors(false);

    setValues((prev) => ({ ...prev, childrenAges: values?.childrenAges }));
    isFunction(onChange) && onChange({ ...values, childrenAges: values?.childrenAges });
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() => setOpenVisitors(true)}
      onBlur={hideContainer}
      css={visitorsContainer}
      {...(className && { className })}>
      <div role="button" tabIndex={0} onClick={(event) => event.preventDefault()} css={visitors}>
        <div className="container-visitors">
          <Icon material iconName="supervisor_account" className="icon" />
          <div className="information">
            <p>{`${values?.adults ?? queryParams?.adults ?? 0} ${getText('adults')}`}</p>
            <div className="dots" />
            <p>{`${values?.rooms ?? queryParams?.rooms ?? 0} ${getText('rooms')}`}</p>
            <div className="dots" />
            <p>{`${values?.children ?? queryParams?.children ?? 0} ${getText('childs')}`}</p>
          </div>
        </div>
      </div>

      {openVisitors && (
        <div className={`container ${tooltipClassName}`}>
          <div className="items">
            <div className="item-info">
              <p className="title">{getText('adults')}</p>
              <p className="subtitle">{getText('adultAges', { fromAges: 13 })}</p>
            </div>
            <CounterInput
              ref={adultsRef}
              value={externalValues.adults}
              onChange={() => {
                !isNil(roomsRef?.current?.value) && roomsRef?.current?.value <= adultsRef?.current?.value
                  ? setShowError(false)
                  : setShowError(true);
                setValues((prev) => ({ ...prev, adults: adultsRef?.current?.value }));
              }}
            />
          </div>
          <div className="items">
            <div className="item-info">
              <p className="title">{getText('rooms')}</p>
            </div>
            <CounterInput
              ref={roomsRef}
              value={externalValues.rooms}
              isOutOfRange={values?.rooms + 1 > values?.adults}
              onChange={() => {
                !isNil(adultsRef?.current?.value) && roomsRef?.current?.value <= adultsRef?.current?.value
                  ? setShowError(false)
                  : setShowError(true);
                setValues((prev) => ({ ...prev, rooms: roomsRef?.current?.value }));
              }}
            />
          </div>
          {showError && <p className="error-message">{getText('roomsCanNotBeMoteThenAdults')}</p>}
          <div className="items">
            <div className="item-info">
              <p className="title">{getText('children')}</p>
              <p className="subtitle">{getText('childAges', { fromAges: 0, toAges: 12 })}</p>
            </div>
            <CounterInput
              ref={childsRef}
              value={externalValues.children}
              onChange={() => setValues((prev) => ({ ...prev, children: childsRef?.current?.value }))}
            />
          </div>
          {!isNil(values?.children) && (
            <Row gap={8} margin="0 0 16px 0">
              {Array(values?.children)
                ?.fill()
                ?.map((el, index) => (
                  <Col key={index} sm={12} md={6}>
                    <Dropdown
                      noClear
                      className="dropdown"
                      label={getText('childAge', { count: index + 1 })}
                      options={childAgeOptions}
                      value={isArray(values.childrenAges) && values.childrenAges[index]}
                      onChange={({ value }) =>
                        setValues((prev) => ({
                          ...prev,
                          childrenAges: prev.childrenAges
                            ? [...prev.childrenAges.map((elem, i) => (index === i ? value : elem)), value].slice(
                                0,
                                values?.children,
                              )
                            : [value],
                        }))
                      }
                    />
                  </Col>
                ))}
            </Row>
          )}

          <Button onClick={handleApplyChanges} className="button" disabled={disableButton}>
            {getText('apply')}
          </Button>
        </div>
      )}
    </div>
  );
};

VisitorsInput.propTypes = {
  values: PropTypes.object,
  className: PropTypes.string,
  tooltipClassName: PropTypes.string,
  onChange: PropTypes.func,
};

export default VisitorsInput;
